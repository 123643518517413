class TechnicalTrendChart {
	constructor(params) {
		this.ajaxPrefix = params.ajaxPrefix || '';
		this.baseId = params.baseId || '';
		this.unitId = params.unitId || '';
		this.range1 = params.range1;
		this.range2 = params.range2;
		this.channelRange1 = params.channelRange1 || params.range1;
		this.channelChartOptions = params.channelChartOptions;
		this.coinName = params.coinName;
		this.coinSymbol = params.coinSymbol;
		this.displayCurrencySymbol = params.displayCurrencySymbol;

		this.noLegend = !!params.noLegend;
		this.noNavigator = !!params.noNavigator;
		this.noRangeSelector = !!params.noRangeSelector;
		this.noScrollbar = !!params.noScrollbar;
		this.noChannelPoints = !!params.noChannelPoints;
		this.noCredits = !!params.noCredits;

		$.when(
			$.getJSON(this.ajaxPrefix + '/aj/coin-simplechartdata?baseCurrencyId=' + this.baseId + '&unitCurrencyId=' + this.unitId + '&range2=' + this.range2 + '&range1=' + this.channelRange1),
			$.getJSON(this.ajaxPrefix + '/aj/chart/mainPriceChannels/' + this.baseId),
			$.getJSON(this.ajaxPrefix + '/aj/chart/actualPricePoints/' + this.baseId)
		).done((price, channels, points) => {

			function prepareSimpleChartData(data) {
				// set the allowed units for data grouping
				var groupingUnits = [[
					'week',                         // unit name
					[1]                             // allowed multiples
				], [
					'month',
					[1, 2, 3, 4, 6]
				]];


				const result = {};
				const utcOffset = moment().utcOffset() * 60 * 1000;

				for (var i = 0; i < data.series.length; i += 1) {
					const series = data.series[i];
					const resultSeries = [];

					for (var j = 0, jMax = series.data.length; j < jMax; ++j) {
						const seriesItem = series.data[j];
						const itemTime = (seriesItem[0] + data.baseTimeSec) * data.baseTimeFactor;
						resultSeries.push([itemTime - utcOffset, seriesItem[1]]);
					}

					if (resultSeries.length > 0) {
						result[series.title] = resultSeries;
					}
				}
				return result;
			}

			function channelHighlight(item, point_id) {
				function point(x, y) {
					return {
						x: x,
						y: y,
						xAxis: 0,
						yAxis: 0
					};
				}

				let color;
				switch (point_id) {
					case 'best_trend':
						color = 'rgba(60, 213, 0, 0.3)';
						break;
					case 'shortest_trend':
						color = 'rgba(202, 0, 209, 0.3)';
						break;
					default:
						color = 'rgba(130, 170, 255, 0.2)';
				}

				var utcOffset = moment().utcOffset() * 60 * 1000;


				return {
					shapes: [{
						type: 'path',
						linkedTo: point_id,
						points:
							[point(item.dateFrom - utcOffset, item.priceFrom + item.width * item.priceFrom),
								point(item.dateTo - utcOffset, item.priceTo + item.width * item.priceTo),
								point(item.dateTo - utcOffset, item.priceTo - item.width * item.priceTo),
								point(item.dateFrom - utcOffset, item.priceFrom - item.width * item.priceFrom)],
						strokeWidth: 0,
						fill: color
					}]
				};
			}

			const praparedData = prepareSimpleChartData(price[0]);

			const seriesOptions = [
				{
					type: 'line',
					zoomType: 'x',
					name: this.coinName + " in " + this.displayCurrencySymbol,
					id: this.coinSymbol + this.displayCurrencySymbol,
					zIndex: 2,
					data: praparedData['price:' + this.displayCurrencySymbol],
					showInNavigator: true,
					cropThreshold: 500,
					dataGrouping: {
						enabled: false
					}
				}
			];

			const annotations = [];


			var pointsData = [];
			var utcOffset = moment().utcOffset() * 60 * 1000;
			for (var i = 0; i < points[0].length; i += 1) {
				pointsData.push({
					x: points[0][i].date - utcOffset,
					title: '$' + Numbers.toPrice(parseFloat(points[0][i].price)),
					text: 'Pivot point at ' + moment(points[0][i].date - utcOffset).format("YYYY-MM-DD HH:mm")
				})
			}

			seriesOptions[1] = {
				type: 'flags',
				data: pointsData,
				onSeries: this.coinSymbol + this.displayCurrencySymbol,  // Id of which series it should be placed on. If not defined
				// the flag series will be put on the X axis
				name: "significant trend change points",
				visible: !this.noChannelPoints,
				zIndex: 100,
				shape: 'flag'  // Defines the shape of the flags.
			};


			$.map(channels[0], (item, i) => {
				var utcOffset = moment().utcOffset() * 60 * 1000;

				// var trendData = [
				//     {x:item.dateFrom-utcOffset, y:item.priceFrom, id: i+"_point" },
				//     [item.dateTo-utcOffset, item.priceTo],
				// ];

				var trendData = [];
				var totalRange = (item.dateTo - item.dateFrom) / 86400000;
				for (var j = 0; j <= totalRange; j++) {
					var date = item.dateFrom - utcOffset + 86400000 * j;
					if (date < moment(this.channelRange1, "YYYY/MM/DD+HH:mm:ss").add(-1, 'days').utc().valueOf()) {
						continue;
					}

					trendData.push(
						{
							x: date,
							y: item.priceFrom - (item.priceFrom - item.priceTo) * (j / totalRange),
							w: item.width
						}
					);
				}
				trendData[0].id = i + "_point";

				seriesOptions.push(
					{
						type: 'line',
						id: i + "_trend",
						className: i + "_trend",
						name: moment(item.dateFrom).fromNow(true) + ' channel (' + i + ")",
						tooltip: {
							pointFormatter: function () {
								const seriesName = this.series.name;
								const options = this.dataGroup ? this.series.options.data[this.dataGroup.start] : this.options;
								const price1 = Numbers.toPrice(parseFloat(options.y));
								const price2 = Numbers.toPrice(parseFloat(options.y + options.y * options.w));
								const price3 = Numbers.toPrice(parseFloat(options.y - options.y * options.w));
								return `<b>${seriesName}</b>: $${price1}<br> top/bottom: $${price2} / $${price3}`;
							}
						},
						data: trendData
					});

				annotations.push(channelHighlight(item, i + "_trend"));
			});


			// create the chart
			const chartElement = params.chartEl || 'coin_channel';
			Highcharts.stockChart(chartElement, Highcharts.merge({
				time: {
					useUTC: false
				},

				credits: {
					enabled: !this.noCredits,
					text: "Bitgur.com",
					href: "https://bitgur.com"
				},

				chart: {
					animation: false,
				},

				rangeSelector: {
					enabled: !this.noRangeSelector,

					buttons: [{
						type: 'hour',
						count: 1,
						text: '1h'
					}, {
						type: 'day',
						count: 1,
						text: '1d'
					}, {
						type: 'day',
						count: 7,
						text: '7d'
					},
						{
							type: 'month',
							count: 1,
							text: '1m'
						}, {
							type: 'month',
							count: 3,
							text: '3m'
						},
						{
							type: 'year',
							count: 1,
							text: '1y'
						}, {
							type: 'all',
							text: 'All'
						}],
					inputEnabled: false, // it supports only days
					selected: 6 // all
				},

				navigator: {
					enabled: !this.noNavigator,

					adaptToUpdatedData: false,
					xAxis: {
						max: moment(new Date().getTime() - moment().utcOffset() * 60 * 1000).valueOf()
					}
				},

				scrollbar: {
					enabled: !this.noScrollbar,

					liveRedraw: false
				},

				legend: {
					enabled: !this.noLegend
				},


				series: seriesOptions,
				annotations: annotations
			}, 'undefined' === typeof channelChartOptions ? {} : channelChartOptions));

		});

	}
}